import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import "./assets/globale.css"
import request from "./utils/request"
import {serverIp} from "../public/config";

Vue.config.productionTip = false

Vue.use(ElementUI, {size:"mini"});

//使用工具类的requst
Vue.prototype.request=request
Vue.prototype.$split_mark='@|'
Vue.prototype.$fill_space=3
Vue.prototype.$upload_space=3
Vue.prototype.base_url=`https://${serverIp}:8090`

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
