import Vue from 'vue'
import VueRouter from 'vue-router'
import ElementUI from "element-ui";

Vue.use(VueRouter)

const routes = [
  {
    path: '/manage',
    name: 'Manage',
    component: () => import(/* webpackChunkName: "about" */ '../views/ManageView.vue'),
    redirect:"/manage",
    children:[
      {
        path:'home',name:'Home',component:()=>import('../views/Home.vue'),
      },
      {
        path: 'questionList',
        name: 'questionList',
        component: () => import('../views/question/questionList'),
      },
      {
        path: 'questionEdit',
        name: 'questionEdit',
        component: () => import('../views/question/questionEdit.vue'),
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/user',
    name: 'user',
    alwaysShow: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/user/usermanage.vue'),
    meta: { title: '用户管理', icon: 'el-icon-user-solid' },
    children: [
      {
        path: 'userList',
        name: 'userList',
        component: () => import('../views/user/userList.vue'),
        meta: { title: '用户列表', icon: 'table' }
      }
    ]
  },
  {
    path: '/home',
    name: 'home',
    alwaysShow: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
    meta: { title: '用户管理', icon: 'el-icon-user-solid' },
    children: [
      {
        path: 'homeIndex',
        name: 'homeIndex',
        component: () => import('../views/Home.vue'),
        meta: { title: '主页', icon: 'table' }
      }
    ]
  },
  {
    path: '/order',
    name: 'order',
    alwaysShow: true,
    meta: { title: '订单数据', icon: 'el-icon-s-help' },
    component: () => import('../views/OrderView.vue'),
    children: [
      {
        path: 'orderMange',
        name: 'orderMange',
        component: () => import('../views/Order.vue'),
        meta: { title: '订单管理', icon: 'el-icon-s-data' }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   const isAuthenticated = localStorage.getItem("user")?true:false;
//   if (to.name === 'Login' || isAuthenticated) {
//     // 如果是登录页面，或者用户已经认证，直接进行路由跳转
//     next();
//   } else {
//     // 如果不是登录页面，并且用户未认证，进行跳转到登录页
//     ElementUI.Message({
//       message: "请先登录",
//       type: 'error'
//     });
//     next({ name: 'Login' });
//   }
// });

export default router
